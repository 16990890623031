import React from "react"
import Layout from "../components/layout"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Metadata from "../components/metadata"

const Services = ({ data }) => {
  const intl = useIntl()
  const { heroImage } = data

  return (
    <>
      <Layout>
        <Metadata title={intl.formatMessage({ id: "services.title" })} />
        <Hero image={heroImage} />
        <h1 className="d-flex m-2 p-3 justify-content-center align-items-center">
          {intl.formatMessage({ id: "services.title" })}
        </h1>
        <hr />
        <div className="container mx-auto">
          <div className="row py-1">
            <div className="col-sm-6 p-3 justify-content-center align-items-center">
              <h2>{intl.formatMessage({ id: "services.subtitle" })}</h2>
              <p className="px-1 px-md-1">
                {intl.formatMessage({ id: "services.left" })}
              </p>
            </div>
            <div className="col-sm-6 justify-content-center align-items-center">
              <p className="px-1 px-md-1 mt-5">
                {intl.formatMessage({ id: "services.right" })}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ServicePageQuery {
    heroImage: file(relativePath: { eq: "services/hero1.jpg" }) {
      childImageSharp {
        fluid {
          src
          srcSet
          aspectRatio
          sizes
        }
      }
    }
  }
`

export default Services
